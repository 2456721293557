import React, { useState } from 'react'
import './createProfile.css'

import { uploadImage } from '../../services/eventService'
import { createProfile } from '../../services/userService'
import { showToast } from '../../services/toastService'
import { useNavigate } from 'react-router-dom'

const ages = ['18-25', '26-35', '36-45', '45+']

const generateUsername = () => {
  const userInfo = localStorage.getItem('user')
  if (userInfo) {
    const { email } = JSON.parse(userInfo)
    const username = email.split('@')[0] + Math.floor(Math.random() * 1000)
    return username
  }
  return ''
}

export default function CreateProfile() {
  const navigate = useNavigate()
  const [isImgUploading, setIsImgUploading] = useState(false)
  const fileInputRef = React.createRef()

  const [formData, setFormData] = useState({
    age_range_id: 1,
    events_publicy: 'everyone',
    username: generateUsername(),
    imageUrl: '',
    name: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).fullName : ''
  })

  const updateFormData = (data) => {
    setFormData({ ...formData, ...data })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const res = await createProfile({ ...formData })
    if (res.success) {
      showToast('Profile created successfully', 'success')
      navigate('/personalize-tags')
    }
  }

  const handleFileChange = async (e) => {
    const file = e.target.files[0]
    setIsImgUploading(true)
    try {
      const formData = new FormData()
      formData.append('image', file)
      const url = await uploadImage(formData)
      updateFormData({ imageUrl: url.data.imageUrl })
    } catch (error) {
      console.error('Error uploading the image:', error)
    } finally {
      setIsImgUploading(false)
    }
  }

  const onAddImage = () => {
    fileInputRef.current.click()
  }

  return (
    <div className="container mx-auto my-auto" style={{ height: '100vh' }}>
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
        <div className="col-lg-6">
          <div className="mt-4">
            <h2>Your Profile</h2>
          </div>
          <div className="d-flex align-items-center">
            <div>
              <img src={formData.imageUrl || '/assets/images/userprofile.png'} width={100} alt="profile" />
            </div>
            <button
              className="mx-4"
              onClick={onAddImage}
              disabled={isImgUploading}
              style={{ border: 'none', background: 'none' }}
            >
              <span className="text-primary border-bottom-primary add-picture">Add Your Picture</span>
            </button>
            <input type="file" ref={fileInputRef} onChange={handleFileChange} style={{ display: 'none' }} />
          </div>
          <main>
            <form onSubmit={handleSubmit}>
              <div className="mt-4 d-flex justify-content-center align-items-center mb-3">
                <span htmlFor="name" className="col-3">
                  Name:
                </span>
                <input
                  type="text"
                  className="form-control ml-2"
                  id="name"
                  name="name"
                  value={formData.name}
                  placeholder="Full Name"
                  required
                />
              </div>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <span htmlFor="Username" className="col-3">
                  Username:
                </span>
                <input
                  type="text"
                  className="form-control ml-2"
                  id="Username"
                  name="Username"
                  value={formData.username}
                  onChange={(e) => updateFormData({ username: e.target.value })}
                  placeholder="Username"
                  required
                />
              </div>
              <div className="d-flex justify-content-center align-items-center mb-3">
                <span htmlFor="bio" className="col-3">
                  Bio:
                </span>
                <textarea
                  type="text"
                  className="form-control ml-2"
                  id="bio"
                  name="bio"
                  rows="4"
                  cols="50"
                  onChange={(e) => updateFormData({ bio: e.target.value })}
                  value={formData.bio}
                  style={{ height: '10rem' }}
                  placeholder=""
                  maxLength={150}
                  required
                />
              </div>
              <div className="d-flex  align-items-center mb-3">
                <span htmlFor="name" className="col-4 col-sm-3">
                  Age Range:
                </span>
                <div style={{ flex: 1 }} className="border-radius-8">
                  <select
                    className="form-control"
                    id="age_range_id"
                    name="age_range_id"
                    value={formData.age_range_id}
                    onChange={(e) => {
                      updateFormData({ age_range_id: e.target.value })
                    }}
                    required
                  >
                    {ages.map((age, index) => (
                      <option key={age} value={index}>
                        {age}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className=" align-items-center mb-3">
                <span htmlFor="name" className=" col-2">
                  Who can see your saved events?
                </span>
                <div className="d-flex justify-start align-items-center">
                  {['everyone', 'friends', 'justme'].map((events_publicy) => (
                    <div className="mx-2" onClick={() => updateFormData({ events_publicy })}>
                      <input
                        type="radio"
                        id={events_publicy}
                        name="events_publicy"
                        value={events_publicy}
                        checked={formData.events_publicy === events_publicy}
                      />
                      <label for={events_publicy} className="p-2">
                        {events_publicy === 'everyone'
                          ? 'Everyone'
                          : events_publicy === 'friends'
                            ? 'Friends'
                            : 'Just Me'}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <button type="submit" className="next-btn" id="continue" style={{ marginBottom: '15px' }}>
                  Next
                </button>
              </div>
            </form>
          </main>
        </div>
      </div>
    </div>
  )
}
