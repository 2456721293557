import React, { useState } from 'react'
import './Register.css'
import GuestModal from './GuestModal'
import { Link } from 'react-router-dom'
import Toasts from '../../common/toasts/Toasts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faEye, faEyeSlash, faUser } from '@fortawesome/free-regular-svg-icons'
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock'
import { faPhone } from '@fortawesome/free-solid-svg-icons'

function Register({
  onSubmit,
  invite,
  loading,
  formData,
  handleChange,
  handleInputBlur,
  errors,
  setShowToast,
  showToast,
  toastMessage
}) {
  const [showModal, setShowModal] = useState(false)
  const isFormValid = !Object.values(errors).some((error) => error)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setConfirmShowPassword] = useState(false)

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword)
  }
  const handleToggleConfirmPassword = () => {
    setConfirmShowPassword((prevShowConfirmPassword) => !prevShowConfirmPassword)
  }

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
        <div className="spinner-grow" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    )
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    onSubmit(formData)
  }

  const handleContinueAsGuest = () => {
    setShowModal(true)
  }

  return (
    <div className="container">
      <div id="register" className="row justify-content-center d-flex align-items-center">
        <div className="col-lg-6">
          <header>
            <a
              href={`/event-goer/event/${invite?.event}/influencer/${invite?.influencerId}`}
              className="inter-700 text-decoration-none text-black"
            >
              <img src="/assets/images/icons/left-arrow.svg" alt="Nevigate to event details" /> Event Details
            </a>
            <div className="text-center mb-5">
              <img src="/assets/images/logo.svg" alt="GreatNight Logo" />
              <h2 id="tagline">Your Social Life Just Got Smarter</h2>
            </div>
            <div>
              <h2 className="mb-1 text-center inter-600">Get Your Ticket!</h2>
              <h3 className="mb-5 text-center inter-600">{invite?.eventName}</h3>
            </div>
          </header>
          <main>
            <Toasts show={showToast} message={toastMessage} onClose={() => setShowToast(false)} />
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <div className="input-group">
                  <label htmlFor="name" className="form-label">
                    Your Name
                  </label>
                  <span className="input-group-text" id="basic-addon1">
                    <FontAwesomeIcon icon={faUser} />
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    onBlur={handleInputBlur}
                    placeholder="Enter your name"
                    required
                  />
                </div>
                <div className="form-text text-danger">{errors?.name}</div>
              </div>
              <div className="mb-4">
                <div className="input-group">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>

                  <span className="input-group-text" id="basic-addon1">
                    <FontAwesomeIcon icon={faEnvelope} />
                  </span>

                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    onBlur={handleInputBlur}
                    placeholder="Enter email"
                    required
                  />
                </div>
                <div className="form-text text-danger">{errors?.email}</div>
              </div>
              <div className="mb-4">
                <div className="input-group">
                  <label htmlFor="cell" className="form-label">
                    Phone
                  </label>

                  <span className="input-group-text" id="basic-addon1">
                    <FontAwesomeIcon icon={faPhone} />
                  </span>

                  <input
                    type="text"
                    className="form-control"
                    id="cell"
                    name="cell"
                    value={formData.cell}
                    onChange={handleChange}
                    onBlur={handleInputBlur}
                    placeholder="Enter phone"
                    required
                  />
                </div>
                <div className="form-text text-danger">{errors?.cell}</div>
              </div>
              <div className="mb-4">
                <div className="input-group">
                  <label htmlFor="cell" className="form-label">
                    Password
                  </label>

                  <span className="input-group-text" id="basic-addon1">
                    <FontAwesomeIcon icon={faLock} />
                  </span>

                  <div style={inputContainerStyle}>
                    <input
                      type={showPassword ? 'text' : 'password'}
                      className="form-control"
                      id="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      onBlur={handleInputBlur}
                      placeholder="Enter password"
                      required
                      style={inputStyle}
                    />
                    <button type="button" onClick={handleTogglePassword} style={toggleButtonStyle}>
                      {showPassword ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                    </button>
                  </div>
                </div>
                <div className="form-text text-danger">{errors?.password}</div>
              </div>
              <div className="mb-4">
                <div className="input-group">
                  <label htmlFor="cell" className="form-label">
                    Confirm Password
                  </label>

                  <span className="input-group-text" id="basic-addon1">
                    <FontAwesomeIcon icon={faLock} />
                  </span>

                  <div style={inputContainerStyle}>
                    <input
                      type={showConfirmPassword ? 'text' : 'password'}
                      className="form-control"
                      id="confirmPassword"
                      name="confirmPassword"
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      onBlur={handleInputBlur}
                      placeholder="Enter password"
                      required
                      style={inputStyle}
                    />
                    <button type="button" onClick={handleToggleConfirmPassword} style={toggleButtonStyle}>
                      {showConfirmPassword ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                    </button>
                  </div>
                </div>
                <div className="form-text text-danger">{errors?.confirmPassword}</div>
              </div>
              <button
                type="submit"
                className="btn mb-5"
                id="sign-up"
                style={{ marginBottom: '15px' }}
                disabled={!isFormValid}
              >
                Sign Up
              </button>

              <button
                type="button"
                className="btn"
                id="continue-as-guest"
                onClick={handleContinueAsGuest}
                data-bs-toggle="modal"
                data-bs-target="#guestModal"
              >
                Continue as Guest
              </button>

              <GuestModal showModal={showModal} setShowModal={setShowModal} />
            </form>
          </main>

          <footer id="footer">
            <p className="mb-1">
              By signing up you agree to our <Link to="/terms-of-service">Terms and Conditions</Link>
            </p>
            <p>
              See how we use your data in our <Link to="/privacy-policy">Privacy Policy</Link>
            </p>
          </footer>
        </div>
      </div>
    </div>
  )
}

const inputContainerStyle = {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  width: 'calc(100% - 40px)'
}

const inputStyle = {
  paddingRight: '60px',
  width: '100%',
  boxSizing: 'border-box'
}

const toggleButtonStyle = {
  position: 'absolute',
  right: '10px',
  padding: '10px',
  fontSize: '16px',
  background: 'none',
  border: 'none',
  cursor: 'pointer'
}

export default Register
