import React, { useEffect, useState } from 'react'

import { updateProfile } from '../../services/userService'
import { showToast } from '../../services/toastService'
import { useNavigate } from 'react-router-dom'
import { classNames } from '../../utils'
import { getInterests } from '../../services/interestService'

export default function PersonalizeTags() {
  const navigate = useNavigate()
  const [selectedTags, setSelectedTags] = useState([])

  const [tags, setTags] = useState([])

  const fetchTags = async () => {
    const data = await getInterests()
    setTags(data.map((tag) => tag.name))
  }

  useEffect(() => {
    fetchTags()
  }, [])

  const handleSubmit = async (e) => {
    if (selectedTags.length < 3) {
      showToast('Please select at least 3 tags', 'error')
      return
    }
    if (selectedTags.length > 6) {
      showToast('Please select at most 6 tags', 'error')
      return
    }
    e.preventDefault()
    const res = await updateProfile({ tags: selectedTags.join(',') })
    if (res.success) {
      showToast('Profile created successfully', 'success')
      navigate('/promoter')
    }
  }

  return (
    <div className="container mx-auto my-auto" style={{ height: '100vh' }}>
      <div className="my-auto mt-4 d-flex justify-content-center align-items-center">
        <div className="col-lg-6">
          <img
            src="/assets/images/logo.svg"
            alt="GreatNight Logo"
            id="logo"
            onClick={() => navigate('/promoter/')}
            style={{ height: '47px' }}
          />
          <div className="mt-4">
            <h2 className="text-2xl text-purple-600">What are you into?</h2>
            <h4 className="text-xl">Select at least 3</h4>
          </div>
          <main>
            <div className="grid grid-cols-3 gap-2 sm:gap-3 mt-4">
              {tags.map((tag) => (
                <div className="grid-span-1">
                  <div
                    onClick={() => {
                      if (selectedTags.find((t) => t === tag)) {
                        setSelectedTags(selectedTags.filter((t) => t !== tag))
                      } else {
                        setSelectedTags([...selectedTags, tag])
                      }
                    }}
                    className={classNames(
                      'p-2 bg-[#e0d8d8] hover:bg-[#cac2c2] card cursor-pointer text-center font-bold',
                      selectedTags.find((t) => t === tag) ? 'bg-purple-300 hover:bg-purple-400' : 'bg-[#e0d8d8] '
                    )}
                  >
                    <h4>{tag}</h4>
                  </div>
                </div>
              ))}
            </div>
            <div className="mt-4 flex justify-end items-center">
              <a href="/promoter" className="text-blue-500  mr-4 border-b">
                Skip
              </a>
              <button className="btn bg-purple-600  hover:bg-purple-500 rounded-2xl" onClick={handleSubmit}>
                <span className="text-white">Next</span>
              </button>
            </div>
          </main>
        </div>
      </div>
    </div>
  )
}
