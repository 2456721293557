/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import moment from 'moment'
import './Events.css'
import { getUpcomingEvents } from '../../../services/eventService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'
import Ad1Component from '../ads/Ads1'
import Ad2Component from '../ads/Ads2'
import Ad3Component from '../ads/Ads3'
import Ad4Component from '../ads/Ads4'
import Ad5Component from '../ads/Ads5'
import { showToast } from '../../../services/toastService'
import { followUser, unfollowUser } from '../../../services/userService'
import { Header } from '../../common/header/Header'

function Events() {
  const location = useLocation()
  const user = location.state?.user || JSON.parse(localStorage.getItem('user'))

  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(true)
  const [tags, setTags] = useState([])
  const [allTags, setAllTags] = useState([])
  const [filteredEvents, setFilteredEvents] = useState([])
  const [selectedTag, setSelectedTag] = useState(null)
  const [selectedDay, setSelectedDay] = useState(null)
  const [thisWeekEvents, setThisWeekEvents] = useState([])
  const [nextWeekEvents, setNextWeekEvents] = useState([])
  const [futureEvents, setFutureEvents] = useState([])

  const dayOfWeekMapping = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6
  }

  useEffect(() => {
    const getEvents = async () => {
      if (user && user.userId && user.role) {
        const response = await getUpcomingEvents(user.userId)
        if (response.success) {
          const combinedEvents = response.data.events
          setEvents(combinedEvents)
          setFilteredEvents(combinedEvents)
          if (Array.isArray(response.data.tags)) {
            setTags(Array.from(new Set(response.data.tags)))
            setAllTags(Array.from(new Set(response.data.tags)))
          }
          setLoading(false)
        } else {
          setLoading(false)
        }
      } else {
        console.error('Please sign in or sign up')
        setLoading(false)
      }
    }

    getEvents()
    // eslint-disable-next-line
  }, [])

  const applyFilters = (tag) => {
    let filtered = events
    if (dayOfWeekMapping[tag] !== undefined) {
      const now = moment()
      const startOfWeek = now.clone().startOf('week')
      const endOfWeek = now.clone().endOf('week')
      const startOfNextWeek = startOfWeek.clone().add(1, 'week')
      const endOfNextWeek = endOfWeek.clone().add(1, 'week')

      const thisWeekEvents = events.filter((event) => {
        const eventDate = moment(event.start)
        return eventDate.isBetween(startOfWeek, endOfWeek, null, '[]')
      })

      const nextWeekEvents = events.filter((event) => {
        const eventDate = moment(event.start)
        return eventDate.isBetween(startOfNextWeek, endOfNextWeek, null, '[]')
      })

      const futureEvents = events.filter((event) => {
        const eventDate = moment(event.start)
        return eventDate.isAfter(endOfNextWeek)
      })

      const groupEventsByDay = (events) => {
        return events.reduce((acc, event) => {
          const day = moment(event.start).format('dddd')
          if (!acc[day]) {
            acc[day] = []
          }
          acc[day].push(event)
          return acc
        }, {})
      }

      const thisWeekEventsByDay = groupEventsByDay(thisWeekEvents)
      const nextWeekEventsByDay = groupEventsByDay(nextWeekEvents)
      const futureEventsByDay = groupEventsByDay(futureEvents)

      const getEventsForDay = (eventsByDay, day) => {
        return eventsByDay[day] || []
      }

      const selectedTag = tag.charAt(0).toUpperCase() + tag.slice(1)
      setSelectedDay(selectedTag)
      const thisWeekFridayEvents = getEventsForDay(thisWeekEventsByDay, selectedTag)
      const nextWeekFridayEvents = getEventsForDay(nextWeekEventsByDay, selectedTag)
      const futureFridayEvents = getEventsForDay(futureEventsByDay, selectedTag)
      setThisWeekEvents(thisWeekFridayEvents)
      setNextWeekEvents(nextWeekFridayEvents)
      setFutureEvents(futureFridayEvents)

      filtered = [...thisWeekFridayEvents, ...nextWeekFridayEvents, ...futureFridayEvents]
    } else if (tag === null) {
      setFilteredEvents(events)
    } else {
      filtered = filtered.filter((event) =>
        event.tags
          .split(',')
          .map((tag) => tag.trim())
          .includes(tag)
      )
    }

    setFilteredEvents(filtered)

    const filteredTags = [...new Set(filtered.flatMap((event) => event.tags.split(',').map((tag) => tag.trim())))]

    if (filteredTags.length > 0) {
      setTags(allTags.filter((tag) => filteredTags.includes(tag)))
    } else {
      setTags(allTags)
    }
  }

  const filterEventsByTag = (tag) => {
    if (tag === null) {
      setThisWeekEvents([])
      setNextWeekEvents([])
      setFutureEvents([])
    }
    setSelectedTag(tag)
    applyFilters(tag)
  }

  const handleFollow = async (follower_id, name) => {
    if (user && user.userId) {
      if (user.userId === follower_id) {
        showToast('You cannot follow yourself', 'error')
      }
      const response = await followUser(user.token, user.userId, follower_id)
      if (response.success) {
        showToast(`You are now following ${name}`, 'success')
        const updatedEvents = filteredEvents.map((event) => {
          if (event.user_id === follower_id) {
            event.is_following = 1
          }
          return event
        })
        setFilteredEvents(updatedEvents)
      } else {
        showToast(response.message, 'error')
      }
    }
  }

  const handleUnfollow = async (follower_id, name) => {
    if (user && user.userId) {
      if (user.userId === follower_id) {
        showToast('You cannot unfollow yourself', 'error')
      }
      const response = await unfollowUser(user.token, user.userId, follower_id)
      if (response.success) {
        showToast(`You have unfollowed ${name}`, 'success')
        const updatedEvents = filteredEvents.map((event) => {
          if (event.user_id === follower_id) {
            event.is_following = 0
          }
          return event
        })
        setFilteredEvents(updatedEvents)
      } else {
        showToast(response.message, 'error')
      }
    }
  }

  const RenderEventDetail = (event, index) => {
    return (
      <div key={`${event.event_id}-${index}`} className="row">
        <div className="col">
          <div className="card event border-0">
            {event.flyer && (
              <Link to={`event/${event.slug}`} className="text-decoration-none">
                <img src={event.flyer} className="card-img-top rounded-3" alt="Event" />
              </Link>
            )}
            <div className="card-body ps-0 pe-0">
              <div className="row">
                <Link to={`event/${event.slug}`} className="text-decoration-none">
                  <div className="col-auto">
                    <div className="d-inline-block ms-2">
                      <h5 className="card-title epilogue-600 text-truncate" style={{ maxWidth: '300px' }}>
                        {event.name}
                      </h5>
                    </div>
                  </div>
                </Link>
                <div className="col text-end"></div>
              </div>
              <div className="row">
                <div className="col-auto">
                  <div className="d-inline-block ms-2">
                    <p className="inter-400 mb-0 location-address">
                      <span className="location-name mb-0">
                        {event.venue_name ? `${event.venue_name}, ` : ''} {event.city}, posted by{' '}
                        <strong>{event.uname}</strong>
                      </span>
                    </p>
                  </div>
                </div>
                <div className="col text-end">
                  {user && user.userId && user.userId !== event.user_id && (
                    <div
                      className={event.is_following ? 'unfollow-badge' : 'follow-badge'}
                      onClick={() =>
                        event.is_following
                          ? handleUnfollow(event.user_id, event.uname)
                          : handleFollow(event.user_id, event.uname)
                      }
                    >
                      <FontAwesomeIcon icon={event.is_following ? faTimes : faPlus} />{' '}
                      {event.is_following ? 'Unfollow' : 'Follow'}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {event.is_promoted ? (
              <span className="promoted-badge position-absolute top-0 start-0 rounded-0 epilogue-400">Promoted</span>
            ) : null}
          </div>
        </div>
      </div>
    )
  }

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
        <div className="spinner-grow" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    )
  }

  return (
    <div id="events" className="container pt-4">
      <div className="row justify-content-center">
        <div className="col-lg-6">
          <Header />
          <main>
            <div className="container-fluid">
              {tags.length > 0 && (
                <>
                  <div className="tag-btn-container">
                    <span
                      style={{
                        fontWeight: 'bold',
                        display: 'block',
                        marginBottom: '10px'
                      }}
                    >
                      Select a tag to filter
                    </span>
                    {selectedTag && (
                      <button id="clear_tag" onClick={() => filterEventsByTag(null)}>
                        Clear Filter
                      </button>
                    )}
                  </div>
                  <div className="tag-container">
                    {tags.map((tag, index) => (
                      <span
                        key={index}
                        onClick={() => filterEventsByTag(tag)}
                        className={selectedTag === tag ? 'selected' : ''}
                      >
                        #{tag}
                      </span>
                    ))}
                  </div>
                </>
              )}

              {dayOfWeekMapping[selectedTag] === undefined && filteredEvents.length > 0
                ? filteredEvents.map((event, index) => (
                    <>
                      {RenderEventDetail(event, index)}

                      {(index + 1) % 5 === 1 && Math.floor(index / 5) % 5 === 0 && <Ad1Component index={index} />}
                      {(index + 1) % 5 === 1 && Math.floor(index / 5) % 5 === 1 && <Ad2Component index={index} />}
                      {(index + 1) % 5 === 1 && Math.floor(index / 5) % 5 === 2 && <Ad3Component index={index} />}
                      {(index + 1) % 5 === 1 && Math.floor(index / 5) % 5 === 3 && <Ad4Component index={index} />}
                      {(index + 1) % 5 === 1 && Math.floor(index / 5) % 5 === 4 && <Ad5Component index={index} />}
                    </>
                  ))
                : null}

              {dayOfWeekMapping[selectedTag] !== undefined && (
                <>
                  {thisWeekEvents.length > 0 && (
                    <>
                      <hr className="mt-5" />
                      <h6 className="epilogue-400 text-center mt-3 mb-3">This Week {selectedDay}</h6>
                      <hr className="mb-3" />
                      {thisWeekEvents.map((event, index) => RenderEventDetail(event, index))}
                    </>
                  )}

                  {nextWeekEvents.length > 0 && (
                    <>
                      <hr className="mt-5" />
                      <h6 className="epilogue-400 text-center mt-3 mb-3">Next Week {selectedDay}</h6>
                      <hr className="mb-3" />
                      {nextWeekEvents.map((event, index) => RenderEventDetail(event, index))}
                    </>
                  )}

                  {futureEvents.length > 0 && (
                    <>
                      <hr className="mt-5" />
                      <h6 className="epilogue-400 text-center mt-3 mb-3">Future Events {selectedDay}</h6>
                      <hr className="mb-3" />
                      {futureEvents.map((event, index) => RenderEventDetail(event, index))}
                    </>
                  )}
                </>
              )}

              {filteredEvents.length === 0 &&
                thisWeekEvents.length === 0 &&
                nextWeekEvents.length === 0 &&
                futureEvents.length === 0 && (
                  <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '50vh' }}>
                    <p className="text-center">
                      There are no events based on the filters you selected, please select another.
                    </p>
                  </div>
                )}
            </div>
          </main>
        </div>
      </div>
    </div>
  )
}

export default Events
