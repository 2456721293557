import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import EventDetails from '../components/event/details/EventDetails'
import { createVibe, getEventDetailsBySlug, rsvpForEvent, setReminder } from '../services/eventService'

function EventDetailsContainer() {
  const { eventId } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const [eventDetails, setEventDetails] = useState(null)
  const [loading, setLoading] = useState(true)
  const user = location.state?.user || JSON.parse(localStorage.getItem('user'))
  const [showToast, setShowToast] = useState(false)
  const [message, setMessage] = useState('')
  const [savedEvent, setSavedEvent] = useState(false)

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const eventData = await getEventDetailsBySlug(eventId)
        setEventDetails(eventData.data)
        const vibes = eventData.vibes
        setSavedEvent(vibes.some((vibe) => vibe.user_id === user.userId))

        const pathParts = location.pathname.split('/')
        const influencerIndex = pathParts.indexOf('influencer')
        const influencerId = influencerIndex !== -1 ? pathParts[influencerIndex + 1] : null

        if (influencerId) {
          localStorage.setItem(
            'invite',
            JSON.stringify({ influencerId: influencerId, event: eventId, eventName: eventData.data.name })
          )
        } else {
          localStorage.setItem(
            'invite',
            JSON.stringify({ influencerId: null, event: eventId, eventName: eventData.data.name })
          )
        }
        setLoading(false)
      } catch (error) {
        console.error('Error fetching event details:', error)
        setLoading(false)
      }
    }

    fetchEventDetails()
    // eslint-disable-next-line
  }, [eventId])

  const handleShareEvent = () => {
    const shareableLink = `${window.location.origin}/event-goer/event/${eventDetails.slug || eventId}/influencer/${user.userId}`

    if (navigator.share) {
      navigator
        .share({
          title: 'Check out this event',
          text: `Join ${eventDetails.name} event`,
          url: shareableLink
        })
        .then(() => console.log('Shared successfully'))
        .catch((error) => console.error('Error sharing:', error))
    } else if (navigator.clipboard) {
      navigator.clipboard
        .writeText(shareableLink)
        .then(() => {
          console.log('Shareable Link:', shareableLink)
          setMessage('Link copied to clipboard')
          setShowToast(true)
        })
        .catch((error) => console.error('Error copying link:', error))
    } else {
      console.log('Shareable Link:', shareableLink)
    }
  }

  const handleRsvpForRegisteredUser = async () => {
    setLoading(true)
    const ticket = await rsvpForEvent()
    const redirectPath = `${window.location.origin}/event-goer/event/tickets/${ticket.data.rsvpId}`
    setLoading(false)
    setMessage(
      `Awesome! You’re Going. Now Let’s Invite Some Friends. <br>View your QR code <a id="scanLink" href="${redirectPath}">here</a>`
    )
    setShowToast(true)
  }

  const handleSaveVibe = async () => {
    setLoading(true)
    const data = {
      eventSlug: eventId,
      userId: user.userId
    }
    const vibe = await createVibe(data)
    const redirectPath = `/promoter/event/vibes`
    setLoading(false)
    navigate(redirectPath, { state: { user: user.data, vibes: vibe.vibes } })
  }

  const setReminderHandler = async () => {
    const data = {
      eventId: eventDetails?.event_id
    }

    const response = await setReminder(data)

    setMessage(response.message)
    setShowToast(true)
  }

  return (
    <EventDetails
      eventDetails={eventDetails}
      loading={loading}
      user={user}
      handleRsvpForRegisteredUser={handleRsvpForRegisteredUser}
      handleSaveVibe={handleSaveVibe}
      handleShareEvent={handleShareEvent}
      setReminderHandler={setReminderHandler}
      setShowToast={setShowToast}
      showToast={showToast}
      toastMessage={message}
      savedEvent={savedEvent}
    />
  )
}

export default EventDetailsContainer
